import React from "react";

interface Props {
  icon: string;
}

const FontAwesomeIcon: React.FC<Props> = ({ 
    icon
  }) => { 
  return (
  	<i className={icon} ></i>
  );
}

export default FontAwesomeIcon;