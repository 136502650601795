import React from "react";
import '../../css/lightbox.css';

class Lightbox extends React.Component {
	render() {
		let videoUrl
		let videoTitle
		let videoDesc
		if(this.props.portfolioItemData) {
			videoUrl = this.props.portfolioItemData.video_url
			videoTitle = this.props.portfolioItemData.video_title
			videoDesc = this.props.portfolioItemData.video_desc
		} else {
			videoUrl = "about:blank"
			videoTitle = " "
			videoDesc = " "
		}
		return (
			<div className={"lightBox " + (this.props.portfolioItemActive && "lightBoxActive") }
            onClick={(e) => this.props.togglelightBox(e) }
            id="lightBox"
            >	
            	<div className="lightVideoWrapper center">
                <iframe src={videoUrl} 
                		title={videoTitle}
                		frameBorder="0" 
                		className="blackbg"
                		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                		allowFullScreen>
                </iframe>
                <div className="lightVideoTitle poppins white">
                	{videoTitle}
                </div> 
                <div className="lightVideoDesc poppins white left" dangerouslySetInnerHTML={{__html: videoDesc}}>
                	
                </div> 
                </div>
                <div className="lightVideoClose white">
                	<i className="far fa-times-circle" id="lightBoxClose" onClick={(e) => this.props.togglelightBox('portfolio') }></i>
                </div>
            </div>
	    )
	}
};
export default Lightbox;