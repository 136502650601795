import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import LightboxAb from '../objects/LightboxAb'
import '../../css/lancamentos.css';

class Lancamentos extends React.Component  {
	render() {
		const albumData = this.props.albumData
		let albumArray = [];
		for (const abData of Object.entries(albumData)) {
			albumArray.push(abData[1]);
		}
		
		function listImgOff(e) {
		    e.target.classList.add('blur');
		    e.target.classList.add('grayscale');
		}
		function listImgOn(e) {
		    e.target.classList.remove('blur');
		    e.target.classList.remove('grayscale');
		}

		return (
			<section id="lancamentos">
				<Row className="percent80"> 
					<Col md={12} className="lancamentosText black poppins">
						<span className="lancamentosTitle comfortaa">lançamentos</span>
						<Row>
							{albumArray.map((album, index) => (
								<Col md={3} className="lancamentosCol center" key={index} >
									<AnimationOnScroll animateIn="animate__zoomIn">
										<div className="lancamentosContent" onClick={ (e) => this.props.portClick(album.obj_ref, 'album') } >
											<div className="lancamentosThumb center">
												<img src={album.album_cover} alt={album.album_name} className="blur grayscale" onMouseOver={listImgOn} onMouseOut={listImgOff} />
											</div>
										</div>	
									</AnimationOnScroll>	
								</Col>
							))}	
						</Row>
					</Col>
				</Row>
				{this.props.albumItemActive &&
					<LightboxAb 
						albumItemActive={this.props.albumItemActive}
						togglelightBox={this.props.togglelightBox}
						albumData={albumData}
						albumItemData={this.props.albumItemData}
					/>
				}
			</section>
		)	
	}
};
export default Lancamentos;	