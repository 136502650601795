import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Lightbox from '../objects/Lightbox'
import '../../css/portfolio.css';

class Portfolio extends React.Component  {
	render() {
		const videoData = this.props.videoData
		let videoArray = [];
		for (const vdData of Object.entries(videoData)) {
			videoArray.push(vdData[1]);
		}
		function listImgOff(e) {
		    e.target.classList.add('blur');
		    e.target.classList.add('grayscale');
		}
		function listImgOn(e) {
		    e.target.classList.remove('blur');
		    e.target.classList.remove('grayscale');
		}
		return (
			<section id="portfolio">
				<Row className="percent80"> 
					<Col md={12} className="portfolioText black poppins">
						<span className="portfolioTitle comfortaa">portfolio</span>
						<Row>
							{videoArray.map((video, index) => (
								<Col md={3} className="portfolioCol center" key={index} >
									<AnimationOnScroll animateIn="animate__zoomIn">
										<div className="portfolioContent" onClick={ (e) => this.props.portClick(video.obj_ref, 'portfolio') } >
											<div className="portfolioThumb center">
												<img src={video.video_tb} alt={video.video_title} className="blur grayscale" onMouseOver={listImgOn} onMouseOut={listImgOff} />
											</div>
										</div>	
									</AnimationOnScroll>	
								</Col>
							))}		
						</Row>
					</Col>
				</Row>
				{this.props.portfolioItemActive &&
					<Lightbox 
						portfolioItemActive={this.props.portfolioItemActive}
						togglelightBox={this.props.togglelightBox}
						videoData={videoData}
						portfolioItemData={this.props.portfolioItemData}
					/>
				}
			</section>
		)	
	}
};
export default Portfolio;	