import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import loadingImg from '../../img/loading.gif';
import '../../css/form.css';

class Form extends React.Component {
	render() {
		const contentData = this.props.contentData
		if (this.props.formData.isSubmitting === 'yes') {
			return(
				<section id="form">
					<Row className="percent80"> 
						<Col md={12} className="formText black poppins">
							<AnimationOnScroll animateIn="animate__slideInLeft">
								<span className="formTitle comfortaa">contato / envie sua demo</span>
							</AnimationOnScroll>	
							<div dangerouslySetInnerHTML={{__html: contentData.texto_form}}></div>
							<img src={loadingImg} alt="Loading..." />
						</Col>
					</Row>
				</section>			
			)
		} else if (this.props.formData.isSubmitting === 'done') {
			return(
				<section id="form">
					<Row className="percent80"> 
						<Col md={12} className="formText black poppins">
							<AnimationOnScroll animateIn="animate__slideInLeft">
								<span className="formTitle comfortaa">contato / envie sua demo</span>
							</AnimationOnScroll>	
							<div dangerouslySetInnerHTML={{__html: contentData.texto_form}}></div>
							<span className="formResponse">{this.props.formData.response}</span>
						</Col>
					</Row>
				</section>	
				
			)	
		} else {
			return (
				<section id="form">
					<Row className="percent80"> 
						<Col md={12} className="formText black poppins">
							<AnimationOnScroll animateIn="animate__slideInLeft">
								<span className="formTitle comfortaa">contato / envie sua demo</span>
							</AnimationOnScroll>	
							<div dangerouslySetInnerHTML={{__html: contentData.texto_form}}></div>
							<form onSubmit={this.props.submitForm}>
								<span className="fieldLabel">
									nome
								</span>
								<div className="fieldDiv">
									<input type="text" name="name" id="name" value={this.props.formData.name} className="poppins black" onChange={this.props.handleInputChange} />
									<div className={"contactDanger dangerbg white roboto center " + (this.props.formErrors.haveErrors.name && "errorActive") } >
										{this.props.formErrors.name}
									</div>
								</div>
								<span className="fieldLabel">
									email
								</span>
								<div className="fieldDiv">
									<input type="email" name="email" id="email" value={this.props.formData.email} className="poppins black" onChange={this.props.handleInputChange} />
									<div className={"contactDanger dangerbg white roboto center " + (this.props.formErrors.haveErrors.email && "errorActive") }>
										{this.props.formErrors.email}
									</div>
								</div>
								<span className="fieldLabel">
									link para demo 
								</span>
								<div className="fieldDiv">
									<input type="text" name="demo" id="demo" value={this.props.formData.demo} className="poppins black" onChange={this.props.handleInputChange} />
									<div className={"contactDanger dangerbg white roboto center " + (this.props.formErrors.haveErrors.demo && "errorActive") } >
										{this.props.formErrors.demo}
									</div>
								</div>
								<span className="fieldLabel">
									mensagem
								</span>
								<div className="fieldDiv">
									<textarea name="msg" id="msg" className="lato black" onChange={this.props.handleInputChange} >
									</textarea>
									<div className={"contactDanger dangerbg white roboto center " + (this.props.formErrors.haveErrors.msg && "errorActive") }>
										{this.props.formErrors.msg}
									</div>	
								</div>	
								<div className="formSubmit">
									<button type="submit" value="enviar">enviar</button>
								</div>
							</form>

						</Col>
					</Row>
				</section>
		    )
		}    
	}
	
};
export default Form;