import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FontAwesomeIcon from '../objects/FontAwesomeIcon'
import '../../css/footer.css';

class Footer extends React.Component  {
	render() {
		const contentData = this.props.contentData
		return (
			<section id="footer">
				<Row className="percent80"> 
					<Col md={12} className="footerLogo center">
						<img src={contentData.home_logo_white} alt="m6 Studio" />
						<img src={contentData.records_logo_white} alt="m6 Records" />
						<div> 
							<a href={contentData.instagram_records} target="_blank" rel="noopener noreferrer">
								<FontAwesomeIcon icon="fa-brands fa-square-instagram" />
							</a>
							<a href={contentData.whatsapp} target="_blank" rel="noopener noreferrer">
								<FontAwesomeIcon icon="fa-brands fa-square-whatsapp" />
							</a>
							<a href="mailto:cello@m6prod.com"><FontAwesomeIcon icon="fa-solid fa-envelope" /></a>
							<a href="mailto:m6records@m6prod.com"><FontAwesomeIcon icon="fa-solid fa-record-vinyl" /></a>
						</div>	
					</Col>
				</Row>
			</section>
		)	
	}
};
export default Footer;	