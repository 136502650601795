import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Image from './objects/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import notImg from '../img/notfound.png';
import '../css/notfound.css';


class NotFound extends Component {
	render() {
		return(
			<Container className="notContainer">
		        <Row>
	           		<Col md={12} className="logImgDiv center poppins">
	           			<a href="/">
	           				<Image href="http://data.m6prod.local/wp-content/uploads/2023/05/logo-m6-studio.png" imgId="logImg" imgClass="logImg" />
	           			</a>	
	           			<span>404 - no Ranma for you!</span>
	           		</Col>
	           	</Row>	
		        <Row className="logRow">
		           	<Col md={12} className="logImgDiv norRanma center">
	           			<Image href={notImg} imgId="logImg" imgClass="logImg" />
	           		</Col>	
		        </Row>
		    </Container>
	    );
	}    
}
export default NotFound;	