import React from "react";

interface Props {
  imgClass: string;
  imgId: string;
  href: string;
}

const Image: React.FC<Props> = ({ 
    imgClass,
    imgId,
    href
  }) => { 
  return (
  	<img src={href} className={imgClass} id={imgId} alt="" />
  );
}

export default Image;