import React, { Component } from 'react';
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import Header from './template/Header'
import Sobre from './template/Sobre'
import Portfolio from './template/Portfolio'
import M6Records from './template/M6Records'
import Lancamentos from './template/Lancamentos'
import Form from './template/Form'
import Footer from './template/Footer'
import Spinner from './objects/Spinner'

class Site extends Component {
	constructor(props){
		super(props);
        this.scrollTo = this.scrollTo.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.portClick = this.portClick.bind(this);
        this.togglelightBox = this.togglelightBox.bind(this);
	}
	state = {
		cookie_key: "m6Data",
		cookie_data: read_cookie("m6Data"),
		siteData: [],
		portfolioItemData: [],
		portfolioItemActive: false,
		albumItemData: [],
		albumItemActive: false,
		loading: true,
		menuActive: false,
		formData: {
          name: "",
          email: "",
          demo: "",
          msg: "",
          isSubmitting: 'no',
          response: "",
        },
    formErrors: {
    	name: "",
      email: "",
      demo: "",
      msg: "",
      haveErrors: {
      	name: false,
        email: false,
        demo: false,
        msg: false,
     		},
     },		
    };

    render() {
    	if (this.state.loading) {
			return(
						<div>
	        		<Spinner />
	        	</div>
	        );
	    } else {
	    	return(
		    	<div>
		    		<Header 
		              contentData={this.state.siteData.contentData}
		              menuActive={this.state.menuActive}
		              scrollTo={this.scrollTo}
		              toggleMenu={this.toggleMenu}
		            />
		            <Sobre
		              contentData={this.state.siteData.contentData}
		            />
		            <Portfolio
		              videoData={this.state.siteData.videoData}
		              portClick={this.portClick}
		              togglelightBox={this.togglelightBox}
              		portfolioItemActive={this.state.portfolioItemActive}
                	portfolioItemData={this.state.portfolioItemData}
		            />
		            <M6Records 
		              contentData={this.state.siteData.contentData}
		            />
		            <Lancamentos 
		              albumData={this.state.siteData.albumData}
		              portClick={this.portClick}
		              togglelightBox={this.togglelightBox}
              		albumItemActive={this.state.albumItemActive}
                	albumItemData={this.state.albumItemData}
		            />
		            <Form
		            	contentData={this.state.siteData.contentData}
		            	formData={this.state.formData}
			            formErrors={this.state.formErrors}
			            handleInputChange={this.handleInputChange}
			            submitForm={this.submitForm}
            		/>
            		<Footer
		              contentData={this.state.siteData.contentData}
		            />
		    	</div>
		    );	
	    }

    }	
    componentDidMount() {
    	fetch(global.config.api_url+'m6-fetch-data/',{method: "GET"})
            .then(res => res.json())
            .then((data) => {
            	this.setState({ siteData: data.siteData })
            	this.setState({ loading: false })
            	console.log(data.siteData.request_method)
            })
            .catch(console.log)
  	}
  	scrollTo(id, block) {
    	document.querySelector('#'+id).scrollIntoView({
        	behavior: 'smooth',
        	block: block
    	});
  	}  
  	handleInputChange = e => this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value}});
  	toggleMenu(e) {
      const currentState = this.state.menuActive;
      if(currentState) {
        this.setState({ menuActive: false });
        document.body.classList.remove('overflowHidden');
      } else {
        this.setState({ menuActive: true })
        document.body.classList.add('overflowHidden');
      }
  	}
  	togglelightBox(clickType) {
  		if(clickType === 'portfolio') {
	  		this.setState({ 
	          portfolioItemActive: false ,
	          portfolioItemData: []
	      }) 
  		}
  		if(clickType === 'album') {
	  			this.setState({ 
	          albumItemActive: false ,
	          albumItemData: []
	      })  
  		}
  		document.body.classList.remove('overflowHidden');
  	}
  	portClick(id, clickType) {
  		if(clickType === 'portfolio') {
	  			this.setState({ 
	          portfolioItemActive: true ,
	          portfolioItemData: this.state.siteData.videoData[id]
	      })  
  		}
  		if(clickType === 'album') {
	  			this.setState({ 
	          albumItemActive: true ,
	          albumItemData: this.state.siteData.albumData[id]
	      })  
  		}
  		document.body.classList.add('overflowHidden');
    }
    
  	submitForm = e => {
      e.preventDefault();
      if(this.validate()) {
        let cookie_val = {
          name: this.state.formData.name,
          email: this.state.formData.email,
        }
        bake_cookie(this.state.cookie_key, cookie_val);
        this.setState({ formData: { ...this.state.formData, isSubmitting: 'yes'}})
        let params = {
          "contact_name": this.state.formData.name,
          "contact_email": this.state.formData.email,
          "contact_demo": this.state.formData.demo,
          "contact_msg": this.state.formData.msg,
        };
        let formData = new FormData();
        formData.append("contact_name", params.contact_name);
        formData.append("contact_email", params.contact_email);
        formData.append("contact_demo", params.contact_demo);
        formData.append("contact_msg", params.contact_msg);
        fetch(global.config.api_url+'m6-fetch-data/', {
        						method: "POST",
        						body: formData,
        					}
        	)
          .then(res => res.json())
          .then((data) => {
                this.setState({ formData: { response: data.siteData.response, isSubmitting: 'done'}})
                console.log(data.siteData.response)
          })
          .catch(console.log)
          ;
        
        

      }
  	}
  	validate() {
      let input = this.state.formData;
      let errors = {};
      errors['haveErrors'] = {}
      let isValid = true;
      errors["haveErrors"]["name"] = false;
      errors["haveErrors"]["email"] = false;
      errors["haveErrors"]["demo"] = false;
      errors["haveErrors"]["msg"] = false;
  
      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Preencha o nome.";
        errors["haveErrors"]["name"] = true;
      }
  
      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Preencha o email.";
        errors["haveErrors"]["email"] = true;
      }
  
      if (typeof input["email"] !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Preencha com um email válido.";
          errors["haveErrors"]["email"] = true;
        }
      }

       if (!input["demo"]) {
        isValid = false;
        errors["demo"] = "Preencha o link para demo.";
        errors["haveErrors"]["demo"] = true;
      }
  
      if (!input["msg"]) {
        isValid = false;
        errors["msg"] = "Preencha a mensagem";
        errors["haveErrors"]["msg"] = true;
      }
  
      this.setState({ formErrors: errors });
  
      return isValid;
  	} 

}
export default Site;		