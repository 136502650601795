import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FontAwesomeIcon from '../objects/FontAwesomeIcon'
import '../../css/lightbox.css';

class LightboxAb extends React.Component {
	render() {
		let title
    	let album_link
    	let album_name
    	let album_tracks
    	let album_cover
    	let release_date
		if(this.props.albumItemData) {
			title = this.props.albumItemData.title
			album_link  = this.props.albumItemData.album_link
			album_name  = this.props.albumItemData.album_name
			album_tracks  = this.props.albumItemData.album_tracks
			album_cover  = this.props.albumItemData.album_cover
			release_date  = this.props.albumItemData.release_date
		} else {
			title = ""
			album_link = ""
			album_name = ""
			album_tracks = ""
			album_cover = ""
			release_date = ""
		}
		return (
			<div className={"lightBox " + (this.props.albumItemActive && "lightBoxActive") }
            onClick={(e) => this.props.togglelightBox(e) }
            id="lightBox"
            >	
            	<div className="lightVideoWrapper">
            		<Row>
            			<Col md={6} className="center albumCover">
            				<img src={album_cover} alt={album_name} />
            			</Col>	
            			<Col md={6}>
            				<span className="lightVideoTitle albumTitle poppins white" dangerouslySetInnerHTML={{__html: title}}>
	                	
			                </span> 
			                <span className="lightVideoDate albumDate poppins white">
			              	  Lançamento: {release_date}
			                </span>
			                <span className="lightVideoDesc albumDesc poppins white left" dangerouslySetInnerHTML={{__html: album_tracks}}>
			                	
			                </span>
			                <a href={album_link} target="_blank" rel="noopener noreferrer"> 
			                	<FontAwesomeIcon icon="fa-brands fa-spotify" />
			                </a>
            			</Col>
            		</Row>
                	
	                
                </div>
                <div className="lightVideoClose white">
                	<i className="far fa-times-circle" id="lightBoxClose" onClick={(e) => this.props.togglelightBox('album') }></i>
                </div>
            </div>
	    )
	}
};
export default LightboxAb;