import React from "react";
import Image from './Image'
import loadingImg from '../../img/loading.gif';
import '../../css/spinner.css';

const Spinner = () => {
    return (
    	<div className="spinnerContainer">
			<div className="row loading">
				<div className="col-md-12 loadingContent comfortaa yellow center">
					<Image href={loadingImg} imgId="spinnerImg" />
				</div>
			</div>
		</div>	
    )
};
export default Spinner;