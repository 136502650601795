import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../../css/sobre.css';

class Sobre extends React.Component  {
	render() {
		const contentData = this.props.contentData
		return (
			<section id="sobre">
				<Row className="percent80"> 
					<Col md={12} className="sobreText black poppins">
						<AnimationOnScroll animateIn="animate__slideInLeft">
							<span className="sobreTitle comfortaa">sobre</span>
						</AnimationOnScroll>	
						<AnimationOnScroll animateIn="animate__fadeInDown">
							<div dangerouslySetInnerHTML={{__html: contentData.sobre}}></div>
						</AnimationOnScroll>	
					</Col>
				</Row>
			</section>
		)	
	}
};
export default Sobre;	