import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FontAwesomeIcon from '../objects/FontAwesomeIcon'
import '../../css/header.css';

class Header extends React.Component  {
	render() {
		const contentData = this.props.contentData
		return (
			<section id="header">
				<Row>
					<Col md={12} className={"menuDesktop poppins white " + (this.props.menuActive && 'menuActive')}>
						<span>
							<FontAwesomeIcon icon="fa-solid fa-house" /> 
							<div className="menuText" onClick={(e) => { this.props.toggleMenu(e); this.props.scrollTo('header', 'start');}}>home</div>
						</span>
						<span>
							<FontAwesomeIcon icon="fa-solid fa-circle-info" /> 
							<div className="menuText" onClick={(e) => { this.props.toggleMenu(e); this.props.scrollTo('sobre', 'start');}}>sobre</div>
						</span>
						<span>
							<FontAwesomeIcon icon="fa-solid fa-file-audio" /> 
							<div className="menuText" onClick={(e) => { this.props.toggleMenu(e); this.props.scrollTo('portfolio', 'start');}}>portfolio</div>
						</span>
						<span>
							<FontAwesomeIcon icon="fa-solid fa-record-vinyl" /> 
							<div className="menuText" onClick={(e) => { this.props.toggleMenu(e); this.props.scrollTo('m6records', 'start');}}>m6 records</div> 
						</span>
						<span>
							<FontAwesomeIcon icon="fa-solid fa-headphones-simple" /> 
							<div className="menuText" onClick={(e) => { this.props.toggleMenu(e); this.props.scrollTo('lancamentos', 'start');}}>lançamentos</div>
						</span>
						<span>
							<FontAwesomeIcon icon="fa-solid fa-envelope" /> 
							<div className="menuText" onClick={(e) => { this.props.toggleMenu(e); this.props.scrollTo('form', 'start');}}>contato</div>
						</span>
						<div className="social center">
							<a href={contentData.instagram_records} target="_blank" rel="noopener noreferrer">
								<FontAwesomeIcon icon="fa-brands fa-square-instagram" />
							</a>
							<a href={contentData.whatsapp} target="_blank" rel="noopener noreferrer">
								<FontAwesomeIcon icon="fa-brands fa-square-whatsapp" />
							</a>
						</div>	
					</Col>
					<Col className={"toggleMenu white " + (this.props.menuActive && 'toggleActive')} >
						<span onClick={(e) => this.props.toggleMenu(e) }>
							<FontAwesomeIcon icon="fa-solid fa-bars" />
						</span>	
					</Col>
				</Row>
				<Row>
					<Col md={12} className="videoHeader">
						<video autoPlay="autoplay" muted loop playsInline>
                    		<source src={contentData.video_bg} type="video/mp4" />
                		</video>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="videoShadow">
						
					</Col>
				</Row>
				<Row>
					<Col md={12} className="homeLogo">
						<img src={contentData.home_logo} alt="m6 Studio" />
					</Col>
				</Row>
			</section>
		)	
	}
};
export default Header;	