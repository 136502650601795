import React from 'react';
import {createRoot} from 'react-dom/client';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import './config/config.js';
import Site from './components/Site.js';
import NotFound from './components/NotFound.js';
import './css/definitions.css';
import './css/mobile.css';
import "animate.css/animate.min.css"; 
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Site />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);