import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../../css/m6records.css';

class M6Records extends React.Component  {
	render() {
		const contentData = this.props.contentData
		return (
			<section id="m6records">
				<Row>
					<Col md={12} className="m6Video">
						<video autoPlay="autoplay" muted loop playsInline>
                    		<source src={contentData.records_bg} type="video/mp4" />
                		</video>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="m6Shadow">
						
					</Col>
				</Row>
				<Row>
					<Col md={12} className="m6Logo">
						<AnimationOnScroll animateIn="animate__slideInLeft">
							<img src={contentData.records_logo} alt="m6 Records" />
						</AnimationOnScroll>	
					</Col>
				</Row>
				<Row className="percent80">
					<Col md={12} className="m6Sobre poppins black">
						<AnimationOnScroll animateIn="animate__fadeInDown">
							<div dangerouslySetInnerHTML={{__html: contentData.sobre_records}}></div>
						</AnimationOnScroll>
					</Col>
				</Row>
			</section>
		)	
	}
};
export default M6Records;	